<template>
    <svg :width="width" :height="height" :class="class1" viewBox="0 0 180 151" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M0 15C0 6.71572 6.71573 0 15 0H75C83.2843 0 90 6.71573 90 15V57.15H0V15Z" fill="#EAEAEA"/>
        <rect y="15.2998" width="180" height="135" rx="25" fill="#AAABAB"/>
    </svg>
</template>

<script>
export default {
    name: "FolderIcon",
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        class1: {
            type: String,
            // Object or array defaults must be returned from
            // a factory function. The function receives the raw
            // props received by the component as the argument.
            default: ""
        },
    }
}
</script>

<style scoped>

</style>